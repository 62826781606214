/* You can add global styles to this file, and also import other style files */

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300&display=swap');
@import '~ngx-toastr/toastr.css';
html,
body {
    height: 100%;
    margin: 0;
}

p,
label,
span {
    letter-spacing: 0.35px;
}

* {
    font-family: 'Raleway', sans-serif;
}


/*------------------------------------------------Encabezado*/

#encabezado {
    background-color: white;
    /* background-color: #005293; */
    /* border-bottom: rgba(16, 53, 133, 0.26) 1px solid; */
    /* height: 70px; */
    height: 100px;
}

#encabezado a {
    color: black;
    padding: 25px
}

#encabezado i {
    color: black;
    padding-right: 40px
}

#encabezado a:hover {
    color: rgba(0, 2, 3, 0.507);
}

#encabezadoIzq>h1 {
    /* color: rgba(5, 50, 71, 0.671); */
    /* color: #005293; */
    color: #335083;
    font-weight: 800;
    text-align: center;
    margin-top: 20px;
}

#body {
    padding-top: 2%;
    margin-left: 1%;
    margin-right: 1%;
    background-color: white;
    /* box-shadow: -10px 0 5px rgb(11, 55, 68); */
    /* box-shadow: -10px 0 5px #005193; */
    box-shadow: -10px 0 5px #335083;
    margin-bottom: 100px;
}


/*----------------------------------------------------Cajita azul*/

#headerPasos {
    /* background: linear-gradient(to bottom, rgba(78, 165, 216, 0.301), white); */
    /* background: linear-gradient(to bottom, #0051938f, white); */
    background: linear-gradient(to bottom, #335083, white);
    border-radius: 25%;
    padding: 15px;
    margin-bottom: 80px;
    text-align: center;
    transition: all 0.2s;
    border-top: 1px solid white;
}

#headerPasos strong {
    font-size: 16px;
    font-weight: 800;
}

#headerPasos p {
    font-size: 13px;
    font-weight: 500;
    font-style: italic;
}


/*---------------------------------------------------Form, inputs...W*/

#pasos {
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 5%;
    margin-bottom: 50px;
    background: rgba(212, 237, 245, 0.103);
}

#bodyPasos div {
    margin-bottom: 40px;
}

#asterisco::after {
    font-weight: bold;
    color: red;
    content: " *";
}

#buttonDcha {
    float: right;
}

textarea {
    resize: none;
}


/*-------------------------------------- Fomrato de los inpus --NO*/

.form {
    width: 50%;
    position: relative;
    height: 50px;
}

.form input {
    border: none;
    padding-top: 20px;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
}

.form label {
    padding-top: -25px;
    position: absolute;
    pointer-events: none;
    width: 100%;
    height: 100%;
    bottom: 0px;
    border-bottom: 1px solid black;
}

.form label::after {
    width: 100%;
    height: 100%;
    bottom: -1px;
    content: '';
    position: absolute;
    border-bottom: 3px solid blue;
    transform: translateX(-100%);
    transition: transform 0.3s ease;
}

.content-name {
    left: 0px;
    bottom: 5px;
    position: absolute;
    transition: all 0.3s ease;
}

.form input:focus+.label-name .content-name,
ç .form input:valid+.label-name .content-name {
    transform: translateY(-150%);
    font-size: 14px;
    color: blue;
}

.form input:focus+.label-name::after,
.form input:valid+.label-name::after {
    transform: translateX(0%);
}


/* */

input {
    padding-left: 50px
}


/* -------------------------------- Volver hacia el Inicio*/

#emoji {
    font-size: 30px;
    color: white;
    background-color: transparent;
    border-radius: 50%;
    margin-left: 25px;
    margin-top: -40px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 2px;
    padding-bottom: 1px;
}

#emoji:hover {
    box-shadow: 5px 5px 25px rgba(8, 59, 88, 0.473);
}